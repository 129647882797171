.feature-section {
  padding-left: 300px;
  padding-right: 300px;
  padding-top: 105px;
  padding-bottom: 129px;
  margin-top: -10px;
  background: #f2f2f2;

  .feature-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 50px;

    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 102px;

      h4 {
        text-transform: capitalize;
        text-align: center;
        font-family: 'Inter', sans-serif;
        font-size: 64px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        color: #2f115c;
        position: relative;

        &::after {
          content: "";
          background-image: url("../../assets/images/short-highlight.svg");
          position: absolute;
          left: 0px;
          bottom: -42px;
          width: 100%;
          height: 40.328px;
          background-size: contain;
          z-index: 100;
          background-repeat: no-repeat;
        }
      }
    }

    .features-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 120px;

      .features-item {
        max-width: 1600px;
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 30px;

        .bg-shadow {
          position: absolute;
          width: 240px;
          height: 240px;
          background: linear-gradient(273deg, #8225ed, #3a005c);
          border-radius: 9999px;
          filter: blur(220px);
        }

        .content {
          width: 40%;
          gap: 0;
          position: relative;

          .title {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 30px !important;

            h6 {
              text-transform: capitalize;
              color: #2b2b2b;
              font-size: 36px;
              font-weight: 900;
              background: linear-gradient(273deg,
                  #ed25c1 2.56%,
                  #a74fff 27.75%,
                  #f096ff 38.3%,
                  #ff96e2 63.35%,
                  #874fff 76.35%,
                  #4d25ed 95.14%);
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }

            .div-line {
              margin-top: 5px;
              width: 100%;
              height: 3px;
              border-radius: 5px;
              background: var(--style-button,
                  linear-gradient(273deg,
                    #ed25c1 2.56%,
                    #a74fff 21.85%,
                    #f096ff 33.42%,
                    #fff 51.26%,
                    #fff 54.64%,
                    #ff96e2 74.41%,
                    #874fff 84.53%,
                    #4d25ed 95.14%));
            }
          }

          p {
            max-width: 500px;
            color: #313131;
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: 158.5%;
            white-space: pre-wrap; // This will preserve the line breaks and spaces

            span {
              background: linear-gradient(273deg,
                  #ed25c1 2.56%,
                  #a74fff 27.75%,
                  #f096ff 38.3%,
                  #ff96e2 63.35%,
                  #874fff 76.35%,
                  #4d25ed 95.14%);
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }

        .rightside {
          width: 60%;
          display: flex;
          justify-content: end;
          align-items: center;
          z-index: 4;

          .rightside-content {
            overflow: hidden;
            border-radius: 20px;

            .multivideo {
              max-height: 350px;
              border-radius: 20px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1576px) {
  .feature-section {
    padding: 40px;
    background: #f2f2f2;

    .feature-content {
      .title {
        margin-bottom: 52px;

        h4 {
          font-size: 44px;
        }
      }

      .features-item {
        .content {
          .title {
            h6 {
              font-size: 30px;
            }

            .div-line {
              width: 90px;
              height: 2px;
            }
          }

          p {
            color: #313131;
            font-family: 'DM Sans', sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 158.5%;
          }
        }

        .rightside {
          width: 50%;
          display: flex;
          justify-content: end;
          align-items: center;

          padding-block: 85px;
          z-index: 4;

          .rightside-content {
            .multivideo {
              width: 473px;
              height: 265.666px;
              border-radius: 20px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 769px) {
  .feature-section {
    padding: 20px;

    .feature-content {
      padding: 40px 10px;

      .title {
        margin-bottom: 30px;
        h4 {
          font-size: 30px;
        }
      }

      .features-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 60px;

        .features-item {
          padding: 0px;
          flex-direction: column;

          .bg-shadow {
            width: 160px;
            height: 160px;
            filter: blur(140px);
          }

          .content {
            width: 100%;
            gap: 10px;

            .title {
              margin-bottom: 10px !important;

              h6 {
                font-size: 24px;
              }
            }

            p {
              font-size: 14px;
            }
          }

          .rightside {
            width: 100%;
            padding: 0px;

            .rightside-content {
              border-radius: 10px;

              .multivideo {
                height: fit-content !important;
                border-radius: 10px;
              }
            }
          }
        }
      }
    }
  }
}