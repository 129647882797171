.navbar-main {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient(90deg,
      #f096ff,
      #d3ccec,
      #e0c7f8,
      #f8f2f2,
      #f8f2f2);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  box-shadow: 0 0 20px 0 #ed25c209, 0 0 20px 0 #a74fff0c, 0 0 30px 0 #ef96ff0c;
  padding: 8px 80px;

  .navbar-container {
    width: 100%;

    .toggle-icon {
      display: nonE;
    }

    .navbar-brand {
      height: 100px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .navbar-ul {
      display: flex;
      gap: 60px;

      .navbaritems {
        position: relative;
        display: flex;
        white-space: nowrap;

        .dropdown-toggle {
          &::after {
            display: none;
          }
        }

        .dropdown-menu {
          border: 3px solid rgb(119, 160, 188);
          gap: 18px;
        }

        a {
          width: fit-content;
          position: relative;
          color: #3f1b78;
          text-align: start;
          font-family: 'DM Sans', sans-serif;
          font-size: 20px;
          font-weight: 600;
          text-transform: capitalize;
          text-decoration: none;
          padding: 0px;
          transition: 0.5s ease-in-out;
          cursor: pointer;

          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0px;
            height: 3px;
            background-image: linear-gradient(45deg,
                #4d25ed,
                #a74fff,
                #f096ff,
                #ffffff,
                #ffffff,
                #ff96e2,
                #874fff,
                #ed25c1);
            transition: 0.5s ease-in-out;
          }

          &:hover {
            &::after {
              width: 100%;
            }

            // background-image: linear-gradient(45deg,
            //     #4d25ed,
            //     #a74fff,
            //     #f096ff,
            //     #ffffff,
            //     #ffffff,
            //     #ff96e2,
            //     #874fff,
            //     #ed25c1);
            background-position: 0 100%;
            /* Set position to bottom */
            background-size: 100% 0.15em;
            /* Adjust thickness of underline as needed */
            background-repeat: no-repeat;
            width: 95%;
          }
        }

        .dropdown-menu {
          border-radius: 16px;
          border: 2.4px solid #c8c2ff;
          background: #f2f2f2;
          margin-top: 10px;

          .dropdown-item {
            color: #2b2b2b;
            font-family: 'DM Sans', sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 158.5%;
            padding-inline: 10px;
            padding-block: 5px;

            &:hover {
              border-radius: 8px;
              background: #c8c2ff;
              width: 100%;
            }
          }
        }
      }
    }

    .last-form {
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 38px;
      margin-top: -8px;

      li {
        text-decoration: none;
        list-style: none;

        .login-navbar {
          width: fit-content;
          color: #3f1b78;
          text-align: center;
          font-family: 'DM Sans', sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: capitalize;
          position: relative;

          &::after {
            position: absolute;
            content: "";
            bottom: 0;
            left: 0;
            width: 0;
            height: 3px;
            background-image: linear-gradient(45deg,
                #4d25ed,
                #a74fff,
                #f096ff,
                #ffffff,
                #ffffff,
                #ff96e2,
                #874fff,
                #ed25c1);
            transition: 0.5s ease-in-out;
          }

          &:hover {
            &::after {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.maininner-new {
  cursor: pointer;
  z-index: 5;
  width: 280px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg,
      #ed25c1,
      #a74fff,
      #f096ff,
      #ffffff,
      #ffffff,
      #ff96e2,
      #874fff,
      #4d25ed);
  overflow: hidden;
  cursor: pointer;
  border-radius: 100px;
  padding: 0px 4px;
  transition: 0.5s ease-in-out;

  .inner {
    display: flex;
    width: 100%;
    height: 54px;
    padding: 25px 10px !important;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: linear-gradient(94deg, #652eac -0.53%, #1f0231 101.1%);
    border-radius: 100px;
    transition: 0.5s ease-in-out;

    &:hover {
      background: #f2f2f2;
      transition: 0.5s ease-in-out;

      span {
        color: #2b2b2b;
      }
    }

    img {
      width: 26px;
      height: 26px;
      transform: rotate(-90deg);
      z-index: 5;
    }

    span {
      color: #fff;
      font-family: 'DM Sans', sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      transition: 0.5s ease-in-out;
      line-height: normal;
    }
  }
}

@keyframes crystalAnimation {
  0% {
    background: linear-gradient(45deg, #fff, transparent);
    background-size: 200% 200%;
    background-position: 0 0;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  100% {
    background: linear-gradient(45deg, transparent, #fff);
    background-size: 200% 200%;
    background-position: 100% 100%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

@media(max-width: 1500px) {
  .navbar-main {
    padding: 8px 20px;

    .navbar-container {
      .navbar-brand {
        width: 80px;
        height: 80px;

        img {
          object-fit: contain;
        }
      }

      .navbar-ul {
        gap: 30px;

        .navbaritems {
          a {
            font-size: 18px;
          }

          .dropdown-menu {
            .dropdown-item {
              font-size: 18px;
            }
          }
        }
      }

      .last-form {
        gap: 20px;
        margin-top: -8px;
        margin-left: 20px;

        li {
          .login-navbar {
            font-size: 18px;
          }
        }
      }
    }
  }

  .maininner-new {
    width: 220px;
    height: 56px;

    .inner {
      height: 50px;
      padding: 20px 10px !important;

      img {
        width: 20px;
        height: 20px;
      }

      span {
        font-size: 18px;
      }
    }
  }
}

@media(max-width: 990px) {
  .navbar-main {
    padding: 10px;

    .navbar-container {

      .navbar-brand {
        height: 60px;
      }

      .toggle-icon {
        display: block;
      }

      .navbar-collapse {
        height: 100vh;
        margin-top: 8px;
        padding: 20px 0px;

        .navbar-nav {
          flex-grow: 1;

          .nav-item {
            width: fit-content;

            .nav-link {
              color: #2b2b2b;
              font-size: 18px;
            }
          }
        }

        form {
          flex-direction: column;
          justify-content: start;
          align-items: start;

          li {
            margin-top: 25px;

            .login-navbar {
              font-size: 18px;
              color: #2b2b2b;
            }
          }

          .maininner-new {
            width: 170px;
            height: 60px;

            .inner {
              width: 160px;
              height: 51px;
              padding: 0px;

              img {
                width: 15px;
              }

              span {
                font-size: 14px;
              }
            }
          }
        }
      }

      .navbar-ul {
        .navbaritems {
          position: relative;
          display: flex;
          flex-direction: column;
          width: fit-content;

          .dropdown-menu {
            position: absolute;
            border-radius: 16px;
            border: 2.4px solid #c8c2ff;
            background: #f2f2f2;
            width: 300px;
            // margin-top: 10px;

            .dropdown-item {
              font-size: 14px;     
              text-wrap: wrap;
              width: 100%;

              &:hover {
                border-radius: 8px;
                background: #c8c2ff;
                width: 100%;
              }
            }
          }
        }
      }

      .last-form {
        margin-left: 0px;
      }
    }
  }
}