.pricing-inner {
  p {
    max-width: 760px;
    width: 100%;
    font-size: 22px;
    margin-top: -25px;
    // line-height: 40px;

    span{
      color: #54099b;
    }
  }
}

.pricing_title {
  text-align: center;

  h1 {
    color: #2b2b2b;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: 'DM Sans', sans-serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 900;
    line-height: 95px;

    span {
      font-variant-numeric: lining-nums proportional-nums;
      font-family: 'DM Sans', sans-serif;
      font-size: 64px;
      font-style: normal;
      font-weight: 900;
      line-height: 95px;
      /* 148.438% */
      background: linear-gradient(274deg,
          #ed25c1 47.37%,
          #a74fff 57.88%,
          #f096ff 64.19%,
          #f181d2 73.91%,
          #ff96e2 86.52%,
          #874fff 92.04%,
          #4d25ed 97.82%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      position: relative;

      &::after {
        content: "";
        background-image: url("../../assets/images/short-highlight.svg");
        position: absolute;
        left: 0px;
        bottom: -45px;
        width: 100%;
        height: 40.328px;
        background-size: contain;
        z-index: 100;
        background-repeat: no-repeat;
      }
    }
  }

  h2 {
    color: #2b2b2b;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: 'DM Sans', sans-serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 900;
    line-height: 95px;
  }
}

.pricingcard-box {
  padding: 28px 25px 28px 31px;
  max-width: 360px;
  min-width: 300px;
  width: 100%;
  height: 100%;
  border-radius: 27.574px;
  background: #fff;

  .card-top {
    display: flex;
    justify-content: space-between;

    h4 {
      font-weight: 700;
      font-size: 27px;
    }

    p {
      display: none;
    }
  }

  .card-content {
    margin-top: 27px;

    span {
      gap: 17px;

      label {
        width: 90%;

        label {
          width: fit-content;
          background: linear-gradient(274deg,
              #ed25c1 47.37%,
              #a74fff 57.88%,
              #f096ff 64.19%,
              #f181d2 73.91%,
              #ff96e2 86.52%,
              #874fff 92.04%,
              #4d25ed 97.82%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }

  .card-bottom-content {
    gap: 14px;
    margin-top: 25px;

    .amt-txt {
      gap: 5px;

      h4 {
        align-items: baseline;
        font-weight: 900;
        font-size: 36px;
      }
    }

    button {
      border-radius: 9.191px;
      background: #f8f4ff;
      text-align: center;
      font-family: 'DM Sans', sans-serif;
      font-size: 18.383px;
      font-weight: 700;
      width: 100%;
      padding: 17px;
      transition: 0.2s ease-in;

      &:hover {
        background: #5e369c;
        color: #f8f4ff;
      }
    }
  }
}

.pro {
  margin-bottom: 32px;
  border-radius: 27.574px;
  background: linear-gradient(88deg, #3f1b78 3.64%, #140231 105.81%);

  .card-top {
    p {
      font-size: 13.787px;
      font-weight: 700;
      padding: 5px 9px !important;
      width: fit-content !important;
      border-radius: 4.596px;
      background: #fff;
      display: block;
    }
  }

  h4 {
    color: #fff;
  }

  label {
    color: #fff;
  }

  .card-bottom-content {
    button {
      background: #5e369c;
      color: #fff;
      font-size: 22.979px;
    }
  }

  .active {
    color: #ffffff !important;
  }

  .notactive {
    color: #89879d !important;
  }
}

.pricing-main-conatiner {
  background-image: url(../../assets/images/pricing.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: fit-content;
  padding: 35px 115px 130px 115px;

  .pricing-inner {
    margin-bottom: 25px;
    margin-top: 69px;
    gap: 30px;

    label {
      max-width: 630px;
      font-family: 'DM Sans', sans-serif;
    }

    .cards-wrapper {
      display: flex;
      gap: 20px;
      align-items: center;
      justify-content: center;
      margin-top: 64px;
      height: 100%;

      .pricingcard-box {
        flex-grow: 1 !important;
        height: 100%;
        width: 100%;
      }
    }
  }
}

.toogle-div {
  gap: 27px;
  margin-top: 20px;

  button {
    background-color: #28104e;
    border: 0;
    border-radius: 50px;
    cursor: pointer;
    height: 27px;
    position: relative;
    width: 64px;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  button:focus,
  button:active {
    outline: none;
  }

  button::-moz-focus-inner {
    border: 0;
  }

  .pin {
    background-color: white;
    border-radius: 98px;
    height: 20px;
    left: 4px;
    position: absolute;
    top: 3px;
    width: 20px;
    transition: left ease 0.5s;
  }

  button.on {
    background-color: #522c8d;
  }

  button.on .pin {
    left: 40px;
  }
}

.subscription-bottom button {
  background: var(--primary-button-background,
      linear-gradient(278deg,
        rgba(241, 185, 255, 0) 17.83%,
        rgba(241, 185, 255, 0.21) 42%,
        rgba(241, 185, 255, 0.21) 60.93%,
        rgba(241, 185, 255, 0) 81.89%),
      linear-gradient(109deg,
        rgba(111, 134, 255, 0.4) 0.67%,
        rgba(111, 134, 255, 0) 141.36%),
      linear-gradient(292deg,
        rgba(111, 134, 255, 0.4) -67.17%,
        rgba(111, 134, 255, 0) 72.61%),
      #3f1b78);
  background-position: 0 0;
  background-size: 200% 100%;
  color: #fff;
  cursor: pointer;
  min-width: 200px;
  font-size: 24px;
  font-weight: 600;
  margin-top: 20px;
  padding: 10px 30px;
  height: 58px;
  text-align: center;
  border-radius: 30px;
  transition: background-position 0.5s cubic-bezier(0.77, 0.01, 0.16, 0.99);

  &:hover {
    transition: background-position 0.5s cubic-bezier(0.77, 0.01, 0.16, 0.99);
    background-position: 100% 0;
  }
}

@media only screen and (max-width: 1500px) {
  .pricing-main-conatiner {
    padding: 30px;

    .pricing-inner {
      .cards-wrapper {
        gap: 16px;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .pricing-main-conatiner {
    padding: 40px 20px !important;

    .pricing-inner {
      h3 {
        font-size: 32px;
        text-align: center;
      }

      label {
        font-size: 16px;
      }

      .cards-wrapper {
        flex-wrap: wrap;
        width: 100%;
      }

      .pro {
        margin-bottom: 0px !important;
      }
    }
  }

  .toogle-div {
    gap: 16px;
  }
}

@media only screen and (max-width: 600px) {
  .pricing_title {
    h1 {
      font-size: 36px;

      span {
        font-size: 36px !important;

        &::after {
          bottom: -39px;
        }
      }
    }

    h2 {
      font-size: 36px !important;
      margin-top: -30px;
    }
  }

  .pricingcard-box {
    padding: 28px 25px 28px 31px;
    max-width: 391px !important;
    width: 100%;

    .card-bottom-content {
      button {
        font-size: 16.383px;
      }
    }
  }

  .text-line-through {
    text-decoration: line-through;
  }

}