.purchase-modal {
  .purchasemodal-content {
    padding: 30px;
    border-radius: 10px;

    .purchase-card {
      border-radius: 27.574px;
      background: #fff;
      
      width: 299px;
      padding: 27px 35px;

      span {
        span {
          align-items: flex-end;
        }
      }
      .amt-txt {
        margin-top: 40px;
        gap: 5px;
        span {
          align-items: baseline;
        }
      }
      button {
        border-radius: 9.191px;
        background: #f8f4ff;
        text-align: center;
        font-family: 'DM Sans', sans-serif;
        font-size: 18.383px;
        font-weight: 700;
        width: 100%;
        padding: 17px;
      }
    }
    .enterprise-card {
      background: linear-gradient(94deg, #3f1b78 -0.53%, #140231 101.1%);
      button {
        background: #5e369c;
        color: #fff;
      }
      svg {
        filter: invert(1);
      }
      .amt-txt{
        label{
          color: #fff;
        }
      }
    }
    .amt-txt{
      margin-bottom: 15px;
    }
    h2.small {
      font-size: 45px;
    }
  }
  .ant-modal-content {
    background: #f2f2f2;
  }
}
@media only screen and (max-width: 1400px) {
  .purchase-modal {
    width: 70vw !important;
  }
}
@media only screen and (max-width: 1200px) {
  .purchase-modal {
    width: 85vw !important;
  }
}
@media only screen and (max-width: 1024px) {
  .purchase-modal {
    .purchasemodal-content {
      padding: 20px;
      flex-wrap: wrap;
      align-items: center;
      .purchase-card {
        width: 289px;
      }
    }
  }
}
