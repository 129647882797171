.video-frame {
    width: 100%;
    // height: 100vh;
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;
    gap: 60px;

    h4 {
        text-transform: capitalize;
        text-align: center;
        font-family: 'Inter', sans-serif;
        font-size: 64px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        color: #2f115c;
        position: relative;
        margin-bottom: 30px;

        img {
            width: 40px;
        }

        &::after {
            content: "";
            background-image: url("../../assets/images/thin-line.png");
            position: absolute;
            left: 0px;
            bottom: -80px;
            width: 100%;
            // height: 40.328px;
            height: 100%;
            background-size: contain;
            z-index: 100;
            background-repeat: no-repeat;
        }
    }

    h3 {
        font-family: 'Inter', sans-serif;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        color: #2f115c;
        width: 100%;
        max-width: 1200px;
        text-align: center;
    }

    .frame {
        width: 100% !important;
        max-width: 1300px !important;
        height: 100% !important;
        box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        border-radius: 30px !important;

        video {
            width: 100% !important;
        }
    }
}

@media (max-width:1526px) {
    .video-frame {
        h4 {
            font-size: 44px;

            img {
                width: 30px;
            }
        }

        .frame {
            height: 500px;
        }
    }
}

@media (max-width:1024px) {
    .video-frame {
        .frame {
            height: 350px;
            border-radius: 15px !important;
        }
    }
}

@media (max-width:769px) {
    .video-frame {
        h4 {
            font-size: 30px;

            img {
                width: 20px;
            }
        }

        .frame {
            height: 250px;

        }
    }
}

@media (max-width:610px) {
    .video-frame {
        padding: 20px;

        .frame {
            height: 175px;
        }
    }
}

@media (max-width:610px) {
    .video-frame {
        .frame {
            height: 200px;
        }
    }
}

@media (max-width:400px) {
    .video-frame {
        .frame {
            height: 170px;
        }
    }
}