@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

// @font-face {
//   font-family: 'DM Sans';
//   src: url("../../assets/font/DMSans.ttf");
// }

// @font-face {
//   font-family: 'Inter';
//   src: url("../../assets/font/Inter.ttf");
// }


h1 {
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0.16px;
}

h3 {
  font-family: 'DM Sans', sans-serif;
  font-size: 24px;
  font-weight: 700;
}

p {
  font-family: 'DM Sans', sans-serif;
  font-size: 20.159px;
  font-weight: 400;
}

p.medium {
  font-size: 18px;
}

p.small {
  font-size: 14px;
}

label {
  color: var(--primary);
  font-family: 'DM Sans', sans-serif;
  font-size: 18.07px;
  font-weight: 700;
}

label.medium {
  font-size: 14px;
}

.text-charcoal {
  color: var(--primary);
}

.text-grey {
  color: var(--gray);
}

.text-blue-gradient {
  background: linear-gradient(270deg, #3385ff 25.58%, #20bdff 87.13%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

a {
  color: #54099b;
}