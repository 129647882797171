.footer {
  background: white;
  padding: 100px;
  position: relative;
  z-index: 999;
  
  .footer-inner {
    max-width: 1320px;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    a {
      cursor: pointer;
    }
    
    .footer-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        h3 {
          color: #191d23;
          font-family: 'DM Sans', sans-serif;
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%; /* 131.25% */
        }
      }
      .right {
        .contact-btn {
          background: var(
            --primary-button-background,
            linear-gradient(
              278deg,
              rgba(241, 185, 255, 0) 17.83%,
              rgba(241, 185, 255, 0.21) 42%,
              rgba(241, 185, 255, 0.21) 60.93%,
              rgba(241, 185, 255, 0) 81.89%
            ),
            linear-gradient(
              109deg,
              rgba(111, 134, 255, 0.4) 0.67%,
              rgba(111, 134, 255, 0) 141.36%
            ),
            linear-gradient(
              292deg,
              rgba(111, 134, 255, 0.4) -67.17%,
              rgba(111, 134, 255, 0) 72.61%
            ),
            #3f1b78
          );
          background-position: 0 0;
          background-size: 200% 100%;
          color: #fff;
          cursor: pointer;
          font-size: 16px;
          font-style: normal;
          font-weight: 800;
          min-width: 200px;
          padding: 16px;
          text-align: center;
          border-radius: 30px;
          transition: background-position 0.5s
            cubic-bezier(0.77, 0.01, 0.16, 0.99);

          &:hover {
            transition: background-position 0.5s
              cubic-bezier(0.77, 0.01, 0.16, 0.99);
            background-position: 100% 0;
          }
        }
      }
    }
    
    .line-verticle {
      background: #8762e9;
      width: 100%;
      height: 2px;
    }
    
    .footer-bottom {
      display: flex;
      justify-content: space-between;
      column-gap: 90px;
      
      .ist {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        border-right: 2px solid #8762e9;
        padding-right: 40px;
        .footer-logo {
          width: 109px;
          height: 122px;
          object-fit: cover;
        }
        .social_icons {
          display: flex;
          gap: 10px;

          .icons {
            width: 30px;
            height: 30px;
            border-radius: 100%;
            background-color: #3a1871;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .icon {
            color: white;
            width: 18px;
            height: 18px;
          }
        }
        p {
          color: #77808b;
          font-family: 'DM Sans', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 24px */
        }
      }
      
      .center {
        display: flex;
        column-gap: 90px;
        .second,
        .third,
        .forth {
          display: flex;
          flex-direction: column;
          row-gap: 20px;
          h6 {
            color: #8762e9;
            font-family: 'DM Sans', sans-serif;

            font-size: 22px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px; /* 100% */
          }
          div {
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            a {
              color: #011334;
              font-family: 'DM Sans', sans-serif;

              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 26px; /* 162.5% */
            }
          }
        }
      }
      
      .fifth {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        border-left: 2px solid #8762e9;
        padding-left: 40px;
        h6 {
          color: #8762e9;
          font-family: 'DM Sans', sans-serif;

          font-size: 22px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px; /* 100% */
        }
        .addresses {
          display: flex;
          flex-direction: column;
          row-gap: 10px;
          div {
            display: flex;
            align-items: center;
            column-gap: 10px;
            p {
              color: #77808b;
              font-family: 'DM Sans', sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 150%; /* 24px */
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .footer {
    padding: 80px;
    .footer-inner {
      row-gap: 30px;

      .footer-top {
        .left {
          h3 {
            font-size: 24px;
          }
        }
        .right {
          .contact-btn {
            padding: 15px 60px;
            font-size: 20px;
            line-height: 150%; /* 157.143% */
          }
        }
      }

      .footer-bottom {
        column-gap: 40px;
        .ist {
          padding-right: 30px;
          .footer-logo {
            width: 90px;
            height: 90px;
            object-fit: contain;
          }

          p {
            line-height: 120%; /* 24px */
          }
        }
        .center {
          column-gap: 35px;
          .second,
          .third,
          .forth {
            h6 {
              font-size: 20px;
              line-height: 100%;
            }
            div {
              a {
                font-size: 16px;
                line-height: 162.5%; /* 162.5% */
              }
            }
          }
        }
        .fifth {
          padding-left: 30px;
          h6 {
            font-size: 20px;
            line-height: 100%;
          }
          .addresses {
            div {
              p {
                line-height: 130%; /* 24px */
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .footer {
    padding: 20px;
    .footer-inner {
      row-gap: 20px;

      .footer-top {
        flex-direction: column;
        row-gap: 20px;
        .left {
          h3 {
            font-size: 20px;
            text-align: center;
            .dev-break {
              display: none;
            }
          }
        }
       
        .right {
          .contact-btn {
            padding: 12px;
            font-size: 14px;
          }
        }
      }
      
      .footer-bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 20px;
        .ist {
          border-right: unset;
          padding: unset;
          align-items: center;
          .social_icons {
            display: flex;
            justify-content: center;
            gap: 10px;
          }
          p {
            font-size: 15px;
          }
        }
        .center {
          display: flex;
          flex-direction: column;
          row-gap: 10px;
          align-items: center;
          .second,
          .third,
          .forth {
            align-items: center;
            row-gap: 10px;
            h6 {
              font-size: 20px;
              text-align: center;
            }
            div {
              display: flex;
              flex-direction: column;
              row-gap: 5px;
              a {
                text-align: center;
              }
            }
          }
        }
        .fifth {
          row-gap: 20px;
          border: unset;
          padding: unset;
          h6 {
            font-size: 20px;
            text-align: center;
          }
          .addresses {
            row-gap: 10px;
            div {
              display: flex;
              align-items: center;
              flex-direction: column;
              gap: 10px;
              p {
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}
