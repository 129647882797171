.contactcomp {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 60px;
  padding-top: 200px;
  background: #f2f2f2;

  .contactUs-main {
    margin-bottom: 10%;
    border-radius: 30px;
    background: #fff;
    max-width: 1296px;
    width: 100%;
    height: 100%;
    filter: drop-shadow(0px 0px 30px rgba(62, 26, 120, 0.3));
    padding: 60px;

    .contact-heading {
      margin-top: 13px;
      color: var(--black, #000);
      font-family: 'DM Sans', sans-serif;
      font-size: 28px;
      opacity: 0.6;
      font-weight: 500;
      margin-bottom: 30px;
    }

    .contact-wrapper {
      display: flex;
      gap: 30px;

      .contact-left {
        width: 50%;
        display: flex;
        justify-content: start;
        // align-items: center;

        .left-content {
          max-width: 527px;

          h5 {
            color: var(--Main-Logotype, #011334);
            font-family: 'DM Sans', sans-serif;
            font-size: 52px;
            font-style: normal;
            font-weight: 700;
            /* 120.833% */
          }
        }
      }

      .right-contact {
        width: 50%;

        .right-content {
          width: 100%;
          height: 100%;
          border-radius: 10px;
          border: 1px solid var(--Gray-4, #bdbdbd);
          background: var(--white, #fff);

          /* Card shadow */
          box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
          padding: 42px 49.616px 26px 50.384px;

          .inputField-section {
            display: flex;
            gap: 21px;
            margin-bottom: 20px;

            .leftside-field {
              width: 100%;
            }

            .leftside-field input {
              width: 100%;
              border-radius: 10px;
              border: 1px solid var(--Gray-3, #828282);
              background: #f9f9f9;
              height: 50px;
              padding-left: 20px;
              padding-right: 10px;
              font-family: 'DM Sans', sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 26px;
              transition: border-color 0.3s, background-color 0.3s, color 0.3s;
              /* Added transition */

              &::placeholder {
                color: var(--Gray-3, #828282);
                font-family: 'DM Sans', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
              }

              &:focus,
              &:active {
                border: 1px solid var(--2-color-grad, #a890d3);
                background: #fff;
                color: var(--Dark-blue-main-logo, #011334);
              }
            }

            .leftside-field textarea {
              resize: none;
              width: 100%;
              height: 272px;
              padding: 10px 20px;
              margin-bottom: 20px;
              /* Adjusted padding */
              color: var(--Gray-3, #828282);
              font-family: 'DM Sans', sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 26px;
              border-radius: 10px;
              border: 1px solid var(--Gray-3, #828282);
              background: #f9f9f9;
              // transition: border-color 0.3s, background-color 0.3s, color 0.3s;
              /* Added transition */
              outline: none;

              &::placeholder {
                color: var(--Gray-3, #828282);
                font-family: 'DM Sans', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
              }

              &:focus,
              &:active {
                border: 1px solid var(--2-color-grad, #a890d3);
                background: #fff;
                color: var(--Dark-blue-main-logo, #011334);
              }
            }

            button {
              background: var(--primary-button-background,
                  linear-gradient(278deg,
                    rgba(241, 185, 255, 0) 17.83%,
                    rgba(241, 185, 255, 0.21) 42%,
                    rgba(241, 185, 255, 0.21) 60.93%,
                    rgba(241, 185, 255, 0) 81.89%),
                  linear-gradient(109deg,
                    rgba(111, 134, 255, 0.4) 0.67%,
                    rgba(111, 134, 255, 0) 141.36%),
                  linear-gradient(292deg,
                    rgba(111, 134, 255, 0.4) -67.17%,
                    rgba(111, 134, 255, 0) 72.61%),
                  #3f1b78);
              background-position: 0 0;
              background-size: 200% 100%;
              color: #fff;
              cursor: pointer;
              font-size: 16px;
              font-style: normal;
              font-weight: 800;
              min-width: 200px;
              width: 100%;
              padding: 16px;
              text-align: center;
              border-radius: 30px;
              transition: background-position 0.5s cubic-bezier(0.77, 0.01, 0.16, 0.99);

              &:hover {
                transition: background-position 0.5s cubic-bezier(0.77, 0.01, 0.16, 0.99);
                background-position: 100% 0;
              }
            }
          }
        }
      }
    }
  }

  .bottom-shadow {
    border-radius: 946px;
    opacity: 0.5;
    background: #8762e9;
    filter: blur(200.35000610351562px);
    width: 700px;
    height: 700px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index: -1;
  }
}

@media only screen and (max-width: 1024px) {
  .contactcomp {
    padding: 30px;
    padding-top: 200px;

    .contactUs-main {
      border-radius: 20px;
      padding: 30px;

      .contact-heading {
        font-size: 18px
      }

      .contact-wrapper {
        flex-direction: column;

        .contact-left {
          width: 100%;

          .left-content {
            max-width: 527px;

            h5 {
              font-size: 36px;
            }
          }
        }

        .right-contact {
          width: 100%;

          .right-content {
            width: 100%;
            padding: 20px;

            .inputField-section {
              .leftside-field input {
                border-radius: 5px;
                height: 42px;

                &::placeholder {
                  font-size: 12px !important;
                }
              }

              .leftside-field textarea {
                height: 200px;
                padding: 10px 20px;

                &::placeholder {
                  font-size: 12px !important;
                }
              }

              button {
                font-size: 14px;
                padding: 12px;
              }
            }
          }
        }
      }
    }
  }
}