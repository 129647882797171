body {
  font-family: 'DM Sans', sans-serif;
  width: 100%;
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

* {
  // outline: 1px solid hotpink;
  // background: #F2F2F2;
}

// ---------------custom margins

.mt-30 {
  margin-top: 30px;
}

// ---------------custom image sizes
.img-100 {

  width: 100%;
  height: 100%;
  object-fit: cover;
}

input {
  outline: none;

  &::-webkit-inner-spin-button {
    display: none;
  }

  &::-webkit-outer-spin-button {
    display: none;
  }
}

.scrollToTop {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 99999 !important;
  cursor: pointer;
  transition: top 0.5s ease-in-out;
  width: 50px;
}

.v-center {
  align-items: center;
  display: flex;
}

.gap-36 {
  gap: 36px;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.h-center {
  display: flex;
  justify-content: center;
}

.v-center {
  display: flex;
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.text-underline {
  text-decoration: underline;
}

.global-btn {
  background: var(
    --primary-button-background,
    linear-gradient(
      278deg,
      rgba(241, 185, 255, 0) 17.83%,
      rgba(241, 185, 255, 0.21) 42%,
      rgba(241, 185, 255, 0.21) 60.93%,
      rgba(241, 185, 255, 0) 81.89%
    ),
    linear-gradient(
      109deg,
      rgba(111, 134, 255, 0.4) 0.67%,
      rgba(111, 134, 255, 0) 141.36%
    ),
    linear-gradient(
      292deg,
      rgba(111, 134, 255, 0.4) -67.17%,
      rgba(111, 134, 255, 0) 72.61%
    ),
    #3f1b78
  );
  background-position: 0 0;
  background-size: 200% 100%;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  
  padding: 16px 32px;
  text-align: center;
  border-radius: 30px;
  transition: background-position 0.5s
    cubic-bezier(0.77, 0.01, 0.16, 0.99);

  &:hover {
    transition: background-position 0.5s
      cubic-bezier(0.77, 0.01, 0.16, 0.99);
    background-position: 100% 0;
  }
}



// ----------------- PRIVACY POLICY ------------------
.privacy-policy {
  height: 100%;
  width: 100%;
  padding: 35px 0px;
  margin-top: 200px;
}

.privacy-policy h1 {
  font-size: 72px;
  font-weight: 700;
}

.privacy-policy h2 {
  font-size: 48px;
  font-weight: 700;
  padding: 10px 0px;
}

.privacy-policy p,
.privacy-policy ul li {
  font-size: 24px;
  font-weight: 400;
  line-height: 1.2cm;
}

.privacy-policy ul li {
  list-style: disc;
  margin-left: 25px;
}



// ----------------- FAQs ------------------

.h-faq {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  font-family: 'DM Sans', sans-serif;
  padding: 30px 0px;

  .accordion-item {
    // background-color: white;
    width: 100%;
    border-bottom: 1px solid rgb(190, 190, 190);

    &:last-child {
      border-bottom: none;
    }

    .accordion-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 30px 20px;
      cursor: pointer;

      .accordion-question {
        font-size: 24px;
        font-weight: 600;
        width: 80%;
        transition: all 0.4s ease-in-out;
      }

      .active {
        color: #4d25ed;
      }

      .acc-icon {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        background-color: #4d25ed44;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
      }
    }

    .accordion-content {
      font-family: 'DM Sans', sans-serif;
      overflow: hidden;
      transition: all 0.4s ease-in-out;

      .accordion-body {
        padding: 30px 20px;
        font-size: 20px;
        letter-spacing: 1.2px;
      }
    }
  }
}

