@import url("./_global.scss");

// base style
@import url("./base/_color.scss");
@import url("./base/_mixins.scss");
@import url("./base/_reset.scss");
@import url("./base/_typography.scss");

// components
@import url("./components/_button.scss");
@import url("./components/_input.scss");
@import url("./components/_select.scss");
@import url("./components/_checkbox.scss");
@import url("./components/_switch.scss");
@import url("./components/_tab.scss");
@import url("./components/_pagination.scss");

.Aibutton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background: linear-gradient(94deg, #3f1b78 7.81%, #140231 101.1%);
  cursor: pointer;
  overflow: hidden;
  width: 160px;
  height: 54px;
  padding: 4px 15px;
  color: #fff;
  text-align: center;
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300 !important;
  line-height: 120%;
  text-transform: capitalize;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .Aibutton {
    border-radius: 50px;
    width: 120px;
    height: 44px;
    font-size: 12px;
    font-weight: 200 !important;
  }
}
