.frequently_main {
  background: #f3f3f3;
  font-family: 'DM Sans', sans-serif;
  padding: 100px 0px;

  .faq-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    max-width: 900px;
    width: 100%;

    .frequently_left {
      text-align: center;

      h1 {
        color: #2b2b2b;
        font-variant-numeric: lining-nums proportional-nums;
        font-family: 'DM Sans', sans-serif;
        font-size: 64px;
        font-style: normal;
        font-weight: 900;
        line-height: 95px;

        span {
          font-variant-numeric: lining-nums proportional-nums;
          font-family: 'DM Sans', sans-serif;
          font-size: 64px;
          font-style: normal;
          font-weight: 900;
          line-height: 95px;
          /* 148.438% */
          background: linear-gradient(274deg,
              #ed25c1 47.37%,
              #a74fff 57.88%,
              #f096ff 64.19%,
              #f181d2 73.91%,
              #ff96e2 86.52%,
              #874fff 92.04%,
              #4d25ed 97.82%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          position: relative;

          &::after {
            content: "";
            background-image: url("../../assets/images/short-highlight.svg");
            position: absolute;
            left: 0px;
            bottom: -45px;
            width: 100%;
            height: 40.328px;
            background-size: contain;
            z-index: 100;
            background-repeat: no-repeat;
          }
        }
      }

      h2 {
        color: #2b2b2b;
        font-variant-numeric: lining-nums proportional-nums;
        font-family: 'DM Sans', sans-serif;
        font-size: 64px;
        font-style: normal;
        font-weight: 900;
        line-height: 95px;
      }
    }

    .h-faq {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: center;
      font-family: 'DM Sans', sans-serif;

      .accordion-item {
        // background-color: white;
        width: 100%;
        border-bottom: 1px solid rgb(190, 190, 190);

        &:last-child {
          border-bottom: none;
        }

        .accordion-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 30px 20px;
          cursor: pointer;

          .accordion-question {
            font-size: 24px;
            font-weight: 600;
            width: 80%;
            transition: all 0.4s ease-in-out;
          }

          .active {
            color: #4d25ed;
          }

          .acc-icon {
            width: 30px;
            height: 30px;
            border-radius: 30px;
            background-color: #4d25ed44;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
          }
        }

        .accordion-content {
          font-family: 'DM Sans', sans-serif;
          overflow: hidden;
          transition: all 0.4s ease-in-out;

          .accordion-body {
            padding: 30px 20px;
            font-size: 20px;
            letter-spacing: 1.2px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .frequently_main {
    padding: 30px 10px;

    .faq-container {
      .frequently_left {
        h1 {
          font-size: 36px;

          span {
            font-size: 36px !important;

            &::after {
              bottom: -39px;
            }
          }
        }

        h2 {
          font-size: 36px !important;
          margin-top: -20px;
        }
      }

      .h-faq {
        .accordion-item {
          .accordion-header {
            padding: 10px;

            .accordion-question {
              font-size: 18px;
            }

            .acc-icon {
              width: 20px;
              height: 20px;
              font-size: 12px !important;
            }
          }

          .accordion-content {
            .accordion-body {
              padding: 10px;

              p {
                font-size: 14px !important;
              }
            }
          }
        }
      }
    }
  }
}