:root {
  --black: #000000;
  --white: #ffffff;
  --primary: #36454f;
  --secondary: #667085;
  --accent: #3385ff;
  --success: #0d894f;
  --success: #0d894f;
  --light-success: #e7f4ee;
  --warning: #e46a11;
  --light-warning: #fdf1e8;
  --danger: #e22400;
  --warning: #f04438;
  --light-warning: #feedec;
  --gray: #b6b6b6;
  --light-gray: #f0f1f3;
  --background: #f9f9fc;
  --dark-gray: #7394c7;
  --light-purple: #89879d;
}

.text-lightpurple {
  color: var(--light-purple);
}