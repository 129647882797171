.platform-sec {
  padding: 130px 100px;
  margin-top: 126px;
  background: white;
  display: flex;
  flex-direction: column;
  row-gap: 65px;
  .top-head {
    max-width: 800px;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    h1 {
      text-align: center;
      font-family: 'DM Sans', sans-serif;
      font-size: 64px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
      img {
        width: 50px;
        height: 50px;
        object-fit: cover;
      }
      span {
        background: linear-gradient(
          273deg,
          #ed25c1 2.56%,
          #a74fff 27.75%,
          #f096ff 38.3%,
          #ff96e2 63.35%,
          #874fff 76.35%,
          #4d25ed 95.14%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    h2 {
      text-align: center;
      color: black;
      font-family: 'DM Sans', sans-serif;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
    }
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 30px;
    .center-line {
      height: 1px;
      background: black;
      width: 100px;
      transform: translateY(-15px);
    }
    .ist {
      display: flex;
      flex-direction: column;
      row-gap: 12px;
      align-items: center;
      svg {
        width: 70px;
        height: 60px;
        object-fit: cover;
      }
      p {
        color: black;
        font-family: 'DM Sans', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
    .second {
      display: flex;
      flex-direction: column;
      row-gap: 12px;
      align-items: center;
      svg {
        width: 142px;
        height: 126px;
        object-fit: cover;
      }
      p {
        color: black;
        font-family: 'DM Sans', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .platform-sec {
    padding: 80px;
    row-gap: 40px;
    .top-head {
      h1 {
        font-size: 40px;
        img {
          width: 30px;
          height: 30px;
        }
      }
      h2 {
        font-size: 24px;
      }
    }
    .content {
      column-gap: 20px;
      .ist {
        svg {
          width: 50px;
          height: 42px;
        }
        p {
          font-size: 16px;
        }
      }
      .second {
        row-gap: 10px;
        svg {
          width: 100px;
          height: 88px;
        }
        p {
          font-size: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .platform-sec {
    padding: 50px 20px;
    margin-top: 110px;
    row-gap: 20px;
    .top-head {
      row-gap: 10px;
      h1 {
        font-size: 36px;
        img {
          width: 20px;
          height: 20px;
        }
      }
      h2 {
        font-size: 18px;
      }
    }
    .content {
      column-gap: 10px;
      .center-line {
        height: 1px;
        background: black;
        width: 60px;
        transform: translateY(-15px);
      }
      .ist {
        row-gap: 10px;
        svg {
          width: 40px;
          height: 40px;
          object-fit: contain;
        }
        p {
          font-size: 16px;
        }
      }
      .second {
        row-gap: 10px;
        svg {
          width: 60px;
          height: 60px;
          object-fit: contain;
        }
        h3 {
          font-size: 18px;
        }
      }
    }
  }
}
