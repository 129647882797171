.info-sec {
  background: #361669;
  padding: 120px 30px;

  h1 {
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: white;
    text-align: center;
    margin-bottom: 40px;

    span {
      background: linear-gradient(273deg,
          #ed25c1 2.56%,
          #a74fff 27.75%,
          #f096ff 38.3%,
          #ff96e2 63.35%,
          #874fff 76.35%,
          #4d25ed 95.14%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .inner {
    max-width: 1400px;
    width: 100%;
    margin: 0px auto;
    display: flex;
    // flex-direction: column;
    // align-items: center;
    justify-content: space-between;
    // flex-direction: column;
    gap: 80px;

    .left {
      max-width: 820px;
      width: 100%;
      // display: flex;
      // flex-direction: column;
      // row-gap: 20px;

      p {
        font-size: 30px;
        font-weight: 400 !important;
        letter-spacing: 1.2px;
        line-height: 1.7em;
        color: white;
        font-family: 'Inter', sans-serif;
        // text-align: center;

      }
    }

    .right {
      max-width: 1200px;
      height: fit-content;
      width: 100%;
      border-radius: 30px;
      overflow: hidden;
      background-color: white;

      video {
        width: 100%;
        padding: 0;
        margin: 0;
        object-fit: contain;
        box-shadow: 0 0 12px rgb(0, 0, 0, 0.3);
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .info-sec {
    .inner {
      max-width: 900px;
      column-gap: 50px;

      .left {
        row-gap: 15px;

        h1 {
          font-size: 30px;
        }

        p {
          font-size: 17px;
        }
      }

      .right {
        max-width: 500px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .info-sec {
    padding: 50px 20px 20px 20px;

    .inner {
      flex-direction: column;
      row-gap: 20px;

      .left {
        row-gap: 10px;

        h1 {
          font-size: 36px;
          text-align: center;
        }

        p {
          font-size: 16px;
          text-align: center;
        }
      }

      .right {
        max-width: 350px;
      }
    }
  }
}