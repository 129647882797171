.element_top {
  background: #f3f3f3;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: auto;
  width: 100%;

  h1 {
    padding: 0 15%;
    color: #2b2b2b;
    text-align: center;
    font-family: 'DM Sans', sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .main {
    margin-top: 2%;
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    position: relative;

    .main-layer {
      position: absolute;
      z-index: 1;
      background-size: cover;
      width: 100%;
    }

    .maininner {
      cursor: pointer;
      z-index: 5;
      margin-top: -15px;
      width: 810px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 80px;
      background: linear-gradient(
        45deg,
        #4d25ed,
        #a74fff,
        #f096ff,
        #ffffff,
        #ffffff,
        #ff96e2,
        #874fff,
        #ed25c1
      );
      border-radius: 10px;
      transition: 0.5s ease-in-out;

      .inner {
        display: flex;
        padding: 25px 20px;
        justify-content: center;
        align-items: center;
        height: 70px;
        gap: 10px;
        background: white;
        width: 100%;
        max-width: 800px;
        border-radius: 10px;
        // border: 4px solid #ed25c1;
        background: #f2f2f2;
        transition: 0.5s ease-in-out;

        &:hover {
          background: linear-gradient(94deg, #652eac -0.53%, #1f0231 101.1%);
          background: #330f54;
          // transition: 0.5s ease-in-out;

          span {
            color: white;
          }
        }

        img {
          width: 32px;
          height: 32px;
          transform: rotate(-90deg);
          z-index: 5;
        }

        span {
          color: #2b2b2b;
          font-family: 'DM Sans', sans-serif;
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
    }
  }
}

@media only screen and (max-width: 1300px) {
  .element_top {
    padding: 0px;

    h1 {
      font-size: 30px;
      padding: 0 15%;
    }

    .main {
      width: 100%;
      height: 300px;

      .maininner {
        width: 560px;
        height: 70px;

        .inner {
          width: 550px;
          height: 60px;

          img {
            width: 22px;
            height: 22px;
          }

          span {
            font-size: 22px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .element_top {
    padding: 0px;

    h1 {
      font-size: 23px;
      padding: 0 15%;
    }

    .main {
      width: 100%;
      height: 300px;

      .maininner {
        margin-top: -5px;
        width: 540px;
        height: 70px;

        .inner {
          width: 530px;
          height: 60px;

          img {
            width: 22px;
            height: 22px;
          }

          span {
            font-size: 22px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 830px) {
  .element_top {
    .main {
      height: 180px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .element_top {
    padding-top: 50px;

    h1 {
      font-size: 20px;
      padding: 0;
    }

    .main {
      width: 100%;
      height: 200px;
      background-size: contain;
      background-repeat: no-repeat;

      .maininner {
        margin-top: 0px;
        width: 306px;
        height: 47px;

        .inner {
          display: flex;
          padding: 15px 10px;
          width: 300px;
          height: 43px;

          img {
            width: 22px;
            height: 22px;
          }

          span {
            // padding-left: 15%;
            font-size: 18px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 580px) {
  .element_top .main {
    background-repeat: no-repeat;
    background-size: contain;
    height: 110px;
    width: 100%;
  }
}
