.herosection {
  width: 100%;
  padding: 30px 20px;
  position: relative;
  overflow: hidden;
  background-color: #f2f2f2;
  background-size: cover;
  display: flex;
  justify-content: end;

  .left_box_shadow {
    position: absolute;
    left: -550px;
    top: 50%;
    border-radius: 706px;
    height: 157px;
    background: linear-gradient(91deg, #139ebc 36.69%, #aa1ecd 91.01%);
    filter: blur(100px);
    width: 706px;
  }

  .right_box_shadow {
    width: 827px;
    height: 157px;
    border-radius: 827px;
    background: linear-gradient(91deg, #aa1ecd 36.69%, #aa1ecd 91.01%);
    filter: blur(100px);
    position: absolute;
    right: -600px;
    top: 50%;
  }

  .herosection-primary {
    width: calc(100%);
    padding-top: 30px;
    padding: 200px 0px 160px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .herosection-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .trail {
        font-family: 'DM Sans', sans-serif;
        margin-top: 30px;
        font-size: 14px;
        color: #020122;
        display: flex;
        align-items: center;
        text-align: center;
        gap: 10px;
      }

      .primary-heading {
        margin-bottom: 36px;
        padding-right: 30px;

        h1 {
          text-align: center;
          color: #2b2b2b;
          font-family: 'Inter', sans-serif !important;
          font-size: 124px;
          // text-transform: capitalize;

          &:first-child {
            color: #431870;
            font-weight: 800 !important;
          }

          span {
            color: #020122;
            font-family: 'DM Sans', sans-serif;
            font-size: 48px;
            font-style: normal;
            line-height: 65px;
            text-transform: capitalize;
            position: relative;

            &::after {
              content: "";
              position: absolute;
              bottom: -35px;
              left: 0px;
              width: 100%;
              height: 40.328px;
              background: linear-gradient();
              background-image: url("../../assets/images/short-highlight.svg");
              z-index: 100;
              background-repeat: no-repeat;
            }
          }
        }
      }

      .secondary-heading {
        max-width: 1149px;

        h2 {
          color: #020122;
          text-align: left;
          font-family: 'DM Sans', sans-serif;
          font-size: 48px;
          font-style: normal;
          font-weight: 900;
          line-height: 140.873%;
          color: #431870;

          .Typewriter {
            .Typewriter__cursor {
              width: 20px !important;
            }
          }

          span {
            background: linear-gradient(274deg,
                #4d25eda1 5.45%,
                #4d25ede0 17.59%,
                #4d25ed 33.24%,
                #4d25ed 41.88%,
                #874fff 63.51%,
                #4d25ed 78.31%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-family: 'DM Sans', sans-serif;
            font-size: 48px;
            font-style: normal;
            font-weight: 900;
            line-height: 100.873%;
            letter-spacing: 3.2px;
            width: 100%;
            text-align: center;
          }

          .Deucesai {
            font-family: 'DM Sans', sans-serif;
            font-size: 48px;
            font-style: normal;
            font-weight: 900;
            line-height: 100.873%;
            position: relative;
            margin-left: 10px;
          }

          .MassEmail {
            span {
              color: #020122;
              text-align: center;
              font-family: 'DM Sans', sans-serif;
              font-size: 64px;
              font-style: normal;
              font-weight: 900;
              line-height: 140.873%;
              letter-spacing: 3.2px;
              padding-top: 40px;
            }
          }
        }
      }

      .trial-button {
        .free-trial {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
          /* Ensure space between icon and text */
          border-radius: 50px;

          background: linear-gradient(274deg,
              #ed25c1 -5.75%,
              #a74fff 18.08%,
              #f096ff 39.99%,
              #ffb1ee 57.28%,
              #ff96e2 73.42%,
              #874fff 100.32%,
              #4d25ed 118.06%);
          box-shadow: 0px 4px 1px 0px rgba(94, 54, 158, 0.75) inset;
          padding: 15px 20px;
          cursor: pointer;
          overflow: hidden;
          /* Hide overflow */
          width: 208px;
          /* Width */
          height: 74px;
          /* Height */
        }

        .trial-icon,
        .free-trials {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          transition: all 0.3s ease;
        }

        .trial-icon {
          width: 44px;
          height: 44px;
          background: #f2f2f2;
          border-radius: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          left: 20px;
        }

        .free-trials {
          color: #fff;
          font-family: 'DM Sans', sans-serif;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: capitalize;
          right: 20px;
        }

        .free-trial:hover .trial-icon {
          left: calc(100% - 44px - 10px);
        }

        .free-trial:hover .free-trials {
          right: calc(100% - 130px);
        }
      }

      .maininner {
        cursor: pointer;
        z-index: 5;
        width: 290px;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(90deg,
            #ed25c1,
            #a74fff,
            #f096ff,
            #ffffff,
            #ffffff,
            #ff96e2,
            #874fff,
            #4d25ed);
        border-radius: 10px;
        transition: all 0.5s linear;

        .inner {
          display: flex;
          width: 280px;
          height: 60px;
          padding: 0px 10px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 10px;
          background: #f2f2f2;
          transition: all 0.5s linear;

          &:hover {
            transition: all 0.5s linear;
            background: linear-gradient(94deg, #652eac -0.53%, #1f0231 101.1%);

            span {
              color: white;
            }
          }

          img {
            width: 32px;
            height: 32px;
            transform: rotate(-90deg);
            z-index: 5;
          }

          span {
            color: #2b2b2b;
            font-family: 'DM Sans', sans-serif;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }
      }
    }

    .heroSection-img-container {
      width: 50%;
    }
  }

  .button-free {
    width: 294px;
    height: 69px;
    color: #2b2b2b;
    font-family: 'DM Sans', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    position: relative;
    background: transparent;
    margin-top: 40px;

    span {
      position: relative;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      width: 100%;
      height: 100%;
      text-align: center;
      border-radius: 100px;
      overflow: hidden;
      transition: all 0.3s linear;
      color: #fff;
      background: linear-gradient(94deg, #652eac -0.53%, #1f0231 101.1%);

      &:hover {
        background: #f2f2f2;
        color: #1f0231;

        img {
          filter: brightness(0) invert(0);
        }
      }
    }

    &::after {
      content: "";
      position: absolute;
      left: -3px;
      top: -3px;
      right: -3px;
      bottom: -3px;
      border-radius: 100px;
      z-index: 0;
      background: linear-gradient(45deg,
          rgba(237, 37, 193, 1),
          rgba(167, 79, 255, 1),
          rgba(240, 150, 255, 1),
          rgba(255, 255, 255, 1),
          rgba(255, 150, 226, 1),
          rgba(135, 79, 255, 1),
          rgba(77, 37, 237, 1));
    }
  }
}

@media only screen and (max-width: 1650px) {
  .herosection {
    width: 100%;
    position: relative;
    overflow: hidden;
  }
}

@media only screen and (max-width: 1600px) {
  .herosection {
    width: 100%;
    position: relative;
    overflow: hidden;
  }
}

@media only screen and (max-width: 1200px) {
  .herosection {
    .herosection-primary {
      width: calc(100%);
      padding-top: 150px;
      padding-left: 20px;
      padding-right: 20px;
      flex-direction: column;
      gap: 50px;

      .herosection-content {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .primary-heading {
          margin-bottom: 10px;
          padding-right: 0px;
          text-align: center;

          h1 {
            font-size: 64px;

            span{
              font-size: 36px;
            }
          }
        }

        .secondary-heading {
          margin-bottom: 65px;
          max-width: 1149px;

          h2 {
            text-align: center;
            font-size: 40px;
            height: auto;

            span {
              font-size: 40px;
            }
          }
        }
      }

      .heroSection-img-container {
        width: 100%;

        img {
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 824px) {
  .herosection {
    width: 100%;
    position: relative;
    overflow: hidden;

    .left_box_shadow {
      display: none;
    }

    .right_box_shadow {
      display: none;
    }


    .herosection-primary {
      padding-bottom: 48px;

      .herosection-content {
        .primary-heading {
          h1 {
            font-size: 42px;
            text-align: center;
          }
        }

        .secondary-heading {
          margin-bottom: 35px;
          max-width: 1149px;

          h2 {
            text-align: center;
            font-size: 20px;
            height: auto;

            span {
              font-size: 20px;
            }
          }
        }

        .maininner {
          width: 170px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          cursor: pointer;
          border-radius: 100px;
          padding: 0px 6px;
          transition: 0.5s ease-in-out;

          .inner {
            padding: 20px 10px;
            gap: 10px;
            max-width: 160px;
            height: 24px;
            border-radius: 10px;
            border-radius: 100px;
            transition: 0.5s ease-in-out;

            img {
              width: 20px;
              height: 20px;
            }

            span {
              font-size: 14px;
            }
          }
        }
      }

      .heroSection-img-container {
        width: 100%;

        img {
          width: 100%;
        }
      }
    }

    .button-free {
      width: 200px;
      height: 50px;
      font-size: 14px;
      margin-top: 0px;


    }
  }
}