.working-steps {
  width: 100%;
  padding: 120px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  justify-content: center;
  background-color: #f4f4f4;
  box-shadow: inset 0 -10px 50px rgba(0, 0, 0, 0.1);

  h1 {
    text-align: center;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;

    span {
      background: linear-gradient(273deg,
          #ed25c1 2.56%,
          #a74fff 27.75%,
          #f096ff 38.3%,
          #ff96e2 63.35%,
          #874fff 76.35%,
          #4d25ed 95.14%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .steps-container {
    max-width: 1400px;
    display: flex;
    flex-direction: column;
    gap: 120px;

    .step-item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 80px;

      .content-box {

        // width: 40%;
        h2 {
          background: linear-gradient(273deg,
              #ed25c1 2.56%,
              #a74fff 27.75%,
              #f096ff 38.3%,
              #ff96e2 63.35%,
              #874fff 76.35%,
              #4d25ed 95.14%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 36px;
          font-weight: 600;
          padding: 30px 0;
        }

        p {
          width: 400px;
        }
      }

      .img-box {
        width: 60%;

        img {
          width: 100%;
          border-radius: 20px;
        }
      }
    }
  }
}

@media (max-width: 1500px) {
  .working-steps {
    .steps-container {
      max-width: 900px;
    }
  }
}

@media (max-width: 1024px) {
  .working-steps {
    width: 100%;
    padding: 20px;

    h1 {
      font-size: 36px;
    }

    .steps-container {
      width: 100%;
      gap: 20px;

      .step-item {
        width: 100%;
        flex-direction: column;
        gap: 20px;

        .content-box {
          width: 100%;

          h2 {
            font-size: 24px;
            padding: 10px 0;
          }

          p {
            width: 100%;
            font-size: 16px;
          }
        }

        .img-box {
          width: 100%;
          padding: 0;

          img {
            width: 100%;
            border-radius: 10px;
            height: fit-content;
          }
        }
      }
    }
  }
}